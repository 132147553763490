<template>
    <div v-if="$route.params.id !=='-1'">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
        <el-breadcrumb-item to="/confirm">确认收益</el-breadcrumb-item>
        <el-breadcrumb-item>意见反馈</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider class="div_der"></el-divider>
      <p class="heade_title">请选择您遇到的问题</p>
      <div class="radio">
        <el-radio-group v-model="radio">
          <el-radio v-for="item in dataList" :label="item.value" :key="item.id" >{{item.label}}</el-radio>
        </el-radio-group>
      </div>
      <el-input placeholder="补充描述，有助于音乐人平台更好地为您服务~" :row="10" resize="none"  type="textarea"  v-model="text" />
      <div class="footer_but">
        <el-button @click="$router.back()">返回</el-button>
        <el-button class="but" :loading="throttle" @click="submit()">提交</el-button>
      </div>
    </div>
    <div v-else>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
        <el-breadcrumb-item to="/confirm">确认收益</el-breadcrumb-item>
        <el-breadcrumb-item>意见反馈</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider class="div_der"></el-divider>
      <div class="default" >
        <img class="default-img" src="../../assets/successIcon.jpg">
        <p class="default-title">提交成功！</p>
        <p class="default-remarks">感谢您的反馈！我们将认真对待您的反馈~</p>
         <el-button @click="$router.replace('/income')">返回确认收益</el-button>
      </div>
    </div>
</template>
<script>
import {dictInfo} from '../../api/dictionary'
import {feedBack_submit} from "../../api/income";
/**
 * Feedback/:id
 * 意见反馈
 */
export default {
  name: "Feedback",
  data(){
    return {
      radio:"",
      text:'',
      dataList:[],
      throttle:false
    }
  },
  created() {
    this.dictInfoFn()
    },
  methods:{
  //  字典
    async dictInfoFn(){
     let res= await  dictInfo({codes:['10013']})
      // console.log(res)
      let {data}=res
       this.dataList=data
      //复一个初始值
      this.radio=data[0].value
    },
   async submit(){
   // 判断理由是否为空
     if(this.text.length===0){
       return this.$popup({msg:'请补充描述~'})
     }
   //提交
     let p = {
       contact:  this.radio,
       contactId:this.$route.params.id,
       content:this.text
     }
     this.throttle=true
     let res= await feedBack_submit(p)
     this.throttle=false
     if(res.code===0){
       this.text='';
       this.$router.push('/feedback/-1');
     }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/works.scss";
.div_der{
  margin-top:21px;
  margin-right:40px;
}
.heade_title{
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #333333;
  margin-bottom:40px;
}
.radio{
   ::v-deep .el-radio{
     margin-right: 73px;
   }
}
::v-deep .el-textarea{
  margin-top: 42px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  .el-textarea__inner{
    height: 280px;
    background: #f8f8f8;
    padding: 20px 30px;
    box-sizing: border-box;
    border: 1px solid #ebebeb;

  }
}
.footer_but{
  text-align: center;
  margin-top: 40px;
  .el-button{
     width: 124px;
    height: 40px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #666666;
  }
  .but{
    margin-left:40px;
    background: #ff004d;
    color: #ffffff;

  }
}
.default{
  margin: 0 auto;
  width: 264px;
  padding-top:176px;
  text-align: center;
  .default-img{
    width: 100px;
    height: 100px;
  }
  .default-title{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
  }
  .default-remarks{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #666660;
    margin: 12px 0 30px 0;
  }
  .el-button{
    width: 124px;
    height: 40px;
    background: #ff004d;
    border-radius: 3px;
    color: #ffffff;
  }
}

</style>
